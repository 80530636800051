var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "material-card",
    { attrs: { color: "primary" } },
    [
      _c(
        "v-card-title",
        [
          _c("v-text-field", {
            attrs: { label: _vm.$t("search"), "hide-details": "" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "v-btn",
            { attrs: { flat: "", icon: "" } },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-magnify"),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "no-data-text": _vm.$t("no-data-available"),
          "rows-per-page-text": _vm.$t("rows-per-page-text"),
          "rows-per-page-items": _vm.globalrowsPerPageItems,
          headers: _vm.headers,
          items: _vm.resultData,
          pagination: _vm.pagination,
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function ({ header }) {
              return [
                _c("span", {
                  staticClass: "subheading font-weight-light text-primary",
                  domProps: { textContent: _vm._s(header.text) },
                }),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    staticClass: "row",
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.update(props.item)
                      },
                    },
                  },
                  [
                    _c("td", [_vm._v(_vm._s(props.item.NoPrescripcion))]),
                    _c("td", [_vm._v(_vm._s(props.item.TipoTec))]),
                    _c("td", [_vm._v(_vm._s(props.item.TipoIDPaciente))]),
                    _c("td", [_vm._v(_vm._s(props.item.NoIDPaciente))]),
                    _c("td", [_vm._v(_vm._s(props.item.FecMaxEnt))]),
                    _c("td", [_vm._v(_vm._s(props.item.CantTotAEntregar))]),
                    _c("td", [_vm._v(_vm._s(props.item.CodSerTecAEntregar))]),
                    _c("td", [_vm._v(_vm._s(props.item.FecDireccionamiento))]),
                    _c(
                      "td",
                      [
                        props.item.status == 1
                          ? _c(
                              "v-btn",
                              { attrs: { color: "primary", small: "" } },
                              [_vm._v("Programar")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }