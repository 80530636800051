export default {
    data() {
        return {
            search: "",
            pagination: {},
            resultData: [],
            headers: [
                {
                    text: "NroPrescrupción",
                    sortable: false,
                    value: "name"
                },
                {
                    text: "TipoTec",
                    sortable: false,
                    value: "providerId"
                },
                {
                    text: "TipoIdPaciente",
                    sortable: false,
                    value: "type"
                },                {
                    text: "NroIdPaciente",
                    sortable: false,
                    value: "type"
                },                {
                    text: "FachaMaxEntrega",
                    sortable: false,
                    value: "type"
                },                {
                    text: "CantTotaEntregar",
                    sortable: false,
                    value: "type"
                },                {
                    text: "CodSerTecAEntregar",
                    sortable: false,
                    value: "type"
                },
                {
                    text: "FecDireccionamiento",
                    sortable: false,
                    value: "source"
                },                {
                    text: "Acción",
                    sortable: false,
                    value: "source"
                }
            ],
            dataTemp: [{
                "ID": 1227508,
                "IDDireccionamiento": 1263,
                "NoPrescripcion": "20131115",
                "TipoTec": "C",
                "ConTec": 1,
                "TipoIDPaciente": "CC",
                "NoIDPaciente": "1032547324",
                "NoEntrega": 1,
                "NoSubEntrega": 0,
                "TipoIDProv": "NI",
                "NoIDProv": "900277244",
                "CodMunEnt": "05001",
                "FecMaxEnt": "2019-06-29",
                "CantTotAEntregar": "120",
                "DirPaciente": "CL 76 # 87 - 63",
                "CodSerTecAEntregar": "00216049-03",
                "NoIDEPS": "800088702",
                "CodEPS": "EPS010",
                "FecDireccionamiento": "2019-04-02 08:16",
                "EstDireccionamiento": "1",
                "FecAnulacion": null,
                "status": "1"
            },
            {
                "ID": 1227508,
                "IDDireccionamiento": 1204663,
                "NoPrescripcion": "20190311195010852445",
                "TipoTec": "M",
                "ConTec": 1,
                "TipoIDPaciente": "CC",
                "NoIDPaciente": "1032508384",
                "NoEntrega": 1,
                "NoSubEntrega": 0,
                "TipoIDProv": "NI",
                "NoIDProv": "900277244",
                "CodMunEnt": "05001",
                "FecMaxEnt": "2019-06-29",
                "CantTotAEntregar": "120",
                "DirPaciente": "CL 76 # 87 - 63",
                "CodSerTecAEntregar": "00216049-03",
                "NoIDEPS": "800088702",
                "CodEPS": "EPS010",
                "FecDireccionamiento": "2019-04-02 08:16",
                "EstDireccionamiento": "1",
                "FecAnulacion": null,
                "status": "2"
            }
            ]
        }
    },
    methods: {
        searchData(data) {
            this.resultData = []
            for (let i = 0; i < this.dataTemp.length; i++) {
                if (this.dataTemp[i].FecMaxEnt == data.date && this.dataTemp[i].EstDireccionamiento == data.stage && this.dataTemp[i].status == data.status) {
                    this.resultData.push(this.dataTemp[i])
                }                
            }
        }
    },
}